import moment from "moment";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Spinner from "./components/Loaders/Spinner";
import { useAxiosGet } from "./hooks/useAxios";
import Guide from "./pages/auth/Guide";
import { authSelector, setUserDetails } from "./redux/slice/authSlice";
import { hideLoader, showLoader } from "./redux/slice/siteLoaderSlice";
import { tokenSelector } from "./redux/slice/tokenSlice";
import { PRIVATE_NAVIGATION, PUBLIC_NAVIGATION } from "./utils/constants";
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const RequiresAuth = React.lazy(() => import("./pages/auth/RequiresAuth"));
const RequiresUnAuth = React.lazy(() => import("./pages/auth/RequiresUnAuth"));
// const BankConsent = React.lazy(
//   () => import("./pages/consumerTrustIndex/BankConsent")
// );
// const BankLogin = React.lazy(
//   () => import("./pages/consumerTrustIndex/BankLogin")
// );
const ConsumerConfiguration = React.lazy(
  () => import("./pages/consumerTrustIndex/Configure")
);
const UpdateProfile = React.lazy(() => import("./pages/profile/UpdateProfile"));
const ConsumerTrustView = React.lazy(
  () => import("./pages/wallet/ConsumerTrustView")
);
const Wallet = React.lazy(() => import("./pages/wallet/Wallet"));
const ConsentConfirmationScreen = React.lazy(
  () => import("./pages/consumerTrustIndex/ConsentConfirmation")
);
const ConsentConfirmScreen = React.lazy(
  () => import("./pages/consumerTrustIndex/ConsentConfirm")
);

export type RouteObjType = {
  path?: string;
  element: JSX.Element;
  children?: RouteObject[];
  errorElement?: JSX.Element;
  feature?: string | boolean;
  permission?: string | boolean;
  organizationType?: string | boolean;
  btnNavigatePath?: string;
  btnTitle?: string;
  sideBarId?: number;
};

const Routes = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const userDetails = useSelector(authSelector);
  const [getUserDetails] = useAxiosGet();
  const publicRoutes = [
    {
      path: PUBLIC_NAVIGATION.consumerInit,
      element: <Guide />,
      sideBarId: 1,
    },
  ];
  const authenticatedRouted: RouteObjType[] = [
    {
      path: PRIVATE_NAVIGATION.profile.edit.path,
      sideBarId: PRIVATE_NAVIGATION.profile.edit.activeSidebar,
      element:
        userDetails?.consent_status === "ACTIVE" &&
        moment(userDetails?.request_timestamp).isAfter(moment()) ? (
          <Wallet />
        ) : (
          <UpdateProfile />
        ),
    },
    {
      path: PRIVATE_NAVIGATION.config.path,
      sideBarId: 0,
      element: <ConsumerConfiguration />,
    },
    // {
    //   path: PRIVATE_NAVIGATION.profile.bankLogin.path,
    //   sideBarId: PRIVATE_NAVIGATION.profile.bankLogin.sideBarId,
    //   element: <BankLogin />,
    // },
    // {
    //   path: PRIVATE_NAVIGATION.profile.bankConsent.path,
    //   sideBarId: PRIVATE_NAVIGATION.profile.bankConsent.sideBarId,
    //   element: <BankConsent />,
    // },
    {
      path: PRIVATE_NAVIGATION.profile.consumerView.path,
      sideBarId: PRIVATE_NAVIGATION.profile.consumerView.activeSidebar,
      element: <ConsumerTrustView />,
    },
    {
      path: PRIVATE_NAVIGATION.profile.consentConfirm.path,
      sideBarId: PRIVATE_NAVIGATION.profile.consentConfirm.activeSidebar,
      element: <ConsentConfirmationScreen />,
    },
    {
      path: PRIVATE_NAVIGATION.profile.consentConfirmScreen.path,
      sideBarId: PRIVATE_NAVIGATION.profile.consentConfirmScreen.activeSidebar,
      element: <ConsentConfirmScreen />,
    },
    {
      path: "*",
      sideBarId: 0,
      element: <PageNotFound />,
    },
  ];

  useEffect(() => {
    if (token) {
      dispatch(showLoader({ header: "", description: "" }));
      getUserData();
    }
  }, [token]);

  const getUserData = async () => {
    const response = await getUserDetails("/consumers/login_consumer_data", {});
    if (response?.data) {
      dispatch(setUserDetails({ userDetails: response?.data }));
    }
    dispatch(hideLoader());
  };

  if (
    !token &&
    Object.values(PUBLIC_NAVIGATION).findIndex(
      (data) => data?.split("/")[1] === window.location.pathname.split("/")[1]
    ) < 0
  ) {
    window.location.href = PUBLIC_NAVIGATION.consumerInit;
  }

  if (
    token &&
    Object.values(PUBLIC_NAVIGATION).findIndex(
      (data) => data?.split("/")[1] === window.location.pathname.split("/")[1]
    ) >= 0
  ) {
    window.location.href = "/";
  }

  const applySuspense = (element: JSX.Element) => {
    return (
      <Suspense
        fallback={
          <div className="h-screen flex justify-center items-center">
            <Spinner className="!h-12 !w-12" />
          </div>
        }
      >
        {element}
      </Suspense>
    );
  };

  const applyRequiresAuth = (routes: RouteObjType[]): RouteObjType[] => {
    return routes.map((route) => {
      return {
        ...route,
        element: applySuspense(
          <RequiresAuth sidebarId={route.sideBarId} path={route.path}>
            {route.element}
          </RequiresAuth>
        ),
      };
    });
  };

  const applyRequiresUnAuth = (routes: RouteObjType[]): RouteObjType[] => {
    return routes.map((route) => ({
      ...route,
      feature: true,
      permission: true,
      organizationType: true,
      element: applySuspense(
        <RequiresUnAuth
          btnText={route?.btnTitle ?? ""}
          btnNavigatePath={route?.btnNavigatePath ?? ""}
          sidebarId={route.sideBarId ?? 0}
        >
          {route.element}
        </RequiresUnAuth>
      ),
    }));
  };

  const authenticationRoutes = applyRequiresUnAuth(publicRoutes);
  const authenticatedRoutes = applyRequiresAuth(authenticatedRouted);
  const finalRoutes = [...authenticationRoutes, ...authenticatedRoutes];
  const router = createBrowserRouter([...finalRoutes]);
  return <RouterProvider router={router} />;
};

export default Routes;
