import NameBadge from "../components/badge/NameBadge";

export const getRandomInt = (max?: number) => {
  return Math.floor(Math.random() * (max ?? 10000000));
};

export const getClassNames = (...classes: (string | undefined)[]) => {
  return classes.filter(Boolean).join(" ");
};

export const toCamelCase = (str: string) => {
  return str
    .replace(/\s(.)/g, function (a) {
      return a.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function (b) {
      return b.toLowerCase();
    });
};

export const URLPattern2 =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
export const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;

export const URLPattern =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g;

export const getLocalStorageData = (storageName: string) => {
  const data = localStorage.getItem(storageName);
  return data ? JSON.parse(data) : null;
};

export const setLocalStorageData = (storageName: string, data: string) => {
  localStorage.setItem(storageName, data);
};

export const generateFirstLetter = (name: string | undefined) => {
  return name ? name.trim().slice(0, 1).toUpperCase() : "";
};

export const isSubmittable = (
  currentValues: { [key: string]: any },
  initialValues: { [key: string]: any },
  validateKeys: string[]
) => {
  let isValidDataFilled = true;
  validateKeys.forEach((data) => {
    if (currentValues[data] === initialValues[data]) {
      isValidDataFilled = false;
    }
  });
  return isValidDataFilled;
};

export const renderNameIcon = ({
  name,
  isCircle,
  className,
  textClassName,
  imageUrl,
  isLimitLongText = false,
}: {
  name: string;
  isCircle?: boolean;
  className?: string;
  textClassName?: string;
  imageUrl?: string;
  isLimitLongText?: boolean;
}) => {
  return (
    <div className="flex justify-start">
      {imageUrl ? (
        <img
          alt=""
          src={imageUrl}
          className={`w-[22px] h-[22px] rounded ${className ?? ""} ${isCircle ? " rounded-xl" : ""}`}
        />
      ) : (
        <NameBadge
          FirstName={name}
          parentClass={`${isCircle ? " rounded-xl" : ""} ${className ?? ""}`}
        />
      )}

      <h6 className={` ${textClassName ?? ""}`}>
        {isLimitLongText && name?.length > 20
          ? `${name.substring(0, 20)}...`
          : name}
      </h6>
    </div>
  );
};

export const wait = (seconds: number): Promise<void> => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000); // converting seconds to milliseconds
  });
};

export const getRiskLevelColor = (risk_level: string, inactive = false) => {
  if (inactive) {
    return "text-grey-content-tertiary";
  } else if (risk_level === "LOW_RISK_CONSUMER") {
    return "text-score-green";
  } else if (risk_level === "MEDIUM_RISK_CONSUMER") {
    return "text-trud-orange";
  } else {
    return "text-red-alert";
  }
};

export const getRiskColor = (
  risk: string,
  inactive: boolean | undefined = false
) => {
  if (inactive) return "bg-grey-content-tertiary";
  switch (risk) {
    case "trusted":
      return "bg-risk-green";
    case "medium":
      return "bg-trud-orange";
    case "high":
      return "bg-risk-red";
    default:
      return "bg-grey-content-tertiary";
  }
};

export const getRiskTitle = (risk: string) => {
  switch (risk) {
    case "trusted":
    case "LOW_RISK":
      return "Trusted Consumer";
    case "medium":
    case "MEDIUM_RISK":
      return "Medium Risk Consumer";
    case "high":
    case "HIGH_RISK":
      return "High Risk Consumer";
    default:
      return "";
  }
};

export const getRiskLevel = (risk_level: string) => {
  if (risk_level === "LOW_RISK_CONSUMER") {
    return "trusted";
  } else if (risk_level === "MEDIUM_RISK_CONSUMER") {
    return "medium";
  } else {
    return "high";
  }
};
