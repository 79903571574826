import DemoGuidePopover from "@/components/DemoGuidePopover";
import { Button } from "@/components/button";
import InputField from "@/components/formElement/InputField";
import SiteHeader from "@/components/siteHeader";
import { useAxiosGet, useAxiosPost } from "@/hooks/useAxios";
import {
  promptDisabledSelector,
  setPromptOpen,
} from "@/redux/slice/promptSlice";
import { setToken } from "@/redux/slice/tokenSlice";
import { isSubmittable } from "@/utils/helpers";
import { ReactComponent as GuideIcon } from "@assets/icons/guideIcon.svg";
import { ReactComponent as CloseIcon } from "@icons/close-white.svg";
import { Form, Formik, FormikValues } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ConsumerInitializeSchema } from "./validations";

interface ConsumerInitialize {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  loyality_Id: string | null;
  projectApiKey: string;
}

const ConsumerTypes = [
  {
    title: "Sandbox consumer data",
    value: "SANDBOX",
  },
  {
    title: "Real Open Banking data",
    value: "PRODUCTION",
  },
];
const initialValues: ConsumerInitialize = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: null,
  loyality_Id: null,
  projectApiKey: "",
};

const Guide = () => {
  const dispatch = useDispatch();
  const isPromptDisabled = useSelector(promptDisabledSelector);
  const [isError, setIsError] = useState<string>("");
  const [consumerInitializeApi] = useAxiosPost();
  const [getDefaultProjectApi] = useAxiosGet();
  const [popUp, setPopUp] = useState<number>(1);
  const [consumerType, setConsumerType] = useState("SANDBOX");
  const [defaultProjectApiKey, setDefaultProjectApiKey] = useState<
    string | null
  >(null);
  const [initialConsumer, setInitialConsumer] =
    useState<ConsumerInitialize>(initialValues);

  useEffect(() => {
    if (!isPromptDisabled) {
      dispatch(setPromptOpen({ isOpen: true }));
    }
  }, []);

  const onSubmit = async (values: FormikValues) => {
    const data = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      ...(values?.phoneNumber && { mobile_number: values?.phoneNumber }),
      ...(values?.loyality_Id && { loyalty_number: values?.loyality_Id }),
      api_key: values?.projectApiKey,
      consumer_type: consumerType,
    };
    const response = await consumerInitializeApi("/consumers/initialize", data);
    if (response?.data?.access_token) {
      dispatch(setToken({ access_token: response?.data?.access_token }));
    } else {
      setIsError(response?.error);
    }
  };

  useEffect(() => {
    const fetchDefaultProject = async () => {
      const response = await getDefaultProjectApi(
        "/consumers/get-default-project"
      );
      if (response?.data) {
        setDefaultProjectApiKey(response.data.api_key);
        setInitialConsumer({
          ...initialConsumer,
          projectApiKey: response.data.api_key,
        });
      }
    };
    if (consumerType === "PRODUCTION") {
      if (!defaultProjectApiKey) fetchDefaultProject();
      else
        setInitialConsumer({
          ...initialConsumer,
          projectApiKey: defaultProjectApiKey,
        });
    } else {
      setInitialConsumer({
        ...initialConsumer,
        phoneNumber: "",
        loyality_Id: "",
        projectApiKey: "",
      });
    }
  }, [consumerType]);

  return (
    <div className="w-full">
      <SiteHeader header="Initialise Consumer" isDisableCTI={true} />
      <div className="max-w-5xl w-full ml-[10%] flex flex-col h-[calc(100vh_-76px)] mt-6">
        {isError && (
          <div className="gap-4 mb-4">
            <div>
              <span
                className={`text-body-black font-medium bg-red-alert-100 mt-1 flex gap-3 p-4`}
              >
                <CloseIcon className="border-transparent rounded-xl bg-red-alert w-[18px] h-[18px] text-white p-[4px]" />
                Sorry, this consumer data does not match any of the details
                provided in our documentation. Please try again with any of the
                provided consumer credentials.
              </span>
            </div>
          </div>
        )}
        <div className="flex gap-2 mb-4">
          {ConsumerTypes.map((type) => (
            <div
              className={`border border-grey-stroke-primary font-semibold cursor-pointer px-4 py-1 rounded-md ${consumerType === type.value ? "bg-grey-stroke-primary text-black" : "text-grey-content-secondary"}`}
              onClick={() => setConsumerType(type.value)}
            >
              {type.title}
            </div>
          ))}
        </div>
        <div className="">
          <h2 className="text-trud-black text-3xl font-medium">
            Manual data entry for consumer to be initialised
          </h2>
          <div className="p-4 flex items-center bg-grey-650 border border-stroke-primary rounded-lg mb-8 gap-2 mt-6 text-grey-content-secondary">
            <div>
              <GuideIcon />
            </div>
            <span className="font-medium">
              {consumerType === "SANDBOX" ? (
                <>
                  Insert consumer data into the form as provided in the{" "}
                  <Link
                    to={
                      "https://docs.trudenty.net/page/initial-data-to-initialise-consumers"
                    }
                    target="_blank"
                    className="underline"
                  >
                    documentation
                  </Link>
                  . If you need to add more than the 5 consumers provided to the
                  sandbox for testing purposes - please let us know.
                </>
              ) : (
                "Enter your full name, email and the project key which can be found in the projects settings in the console. To revisit your previous score, ensure you use the same details that were entered during your previous session."
              )}
            </span>
          </div>
          <div className={`px-4 mt-5 `}>
            <Formik
              initialValues={initialConsumer}
              enableReinitialize={true}
              validationSchema={ConsumerInitializeSchema}
              onSubmit={onSubmit}
            >
              {({ values }) => {
                return (
                  <Form>
                    <div className="grid grid-cols-12 gap-8">
                      <div className="col-span-12 md:col-span-6">
                        <InputField
                          label="First name"
                          type="text"
                          name="firstName"
                          placeholder="Enter First Name"
                          className="rounded-lg !bg-white border border-grey-content-quaternary text-body-black"
                          isCompulsory={true}
                        />
                      </div>
                      <div className=" col-span-12 md:col-span-6">
                        <InputField
                          label="Last name"
                          type="text"
                          name="lastName"
                          placeholder="Enter Last Name"
                          className="rounded-lg !bg-white border border-grey-content-quaternary text-body-black"
                          isCompulsory={true}
                        />
                      </div>
                      <div
                        className={` col-span-12 md:col-span-${consumerType === "SANDBOX" ? "6" : "12"}`}
                      >
                        <InputField
                          label="Email"
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          className="rounded-lg !bg-white border border-grey-content-quaternary text-body-black"
                          isCompulsory={true}
                        />
                      </div>
                      {consumerType === "SANDBOX" && (
                        <div className=" col-span-12 md:col-span-6">
                          <InputField
                            label="Phone number"
                            type="text"
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                            className="rounded-lg !bg-white border border-grey-content-quaternary text-body-black"
                          />
                        </div>
                      )}
                      {consumerType === "SANDBOX" && (
                        <div className="col-span-12">
                          <InputField
                            label="Loyalty ID"
                            type="text"
                            name="loyality_Id"
                            placeholder="Enter Loyalty ID"
                            className="rounded-lg !bg-white border border-grey-content-quaternary text-body-black"
                          />
                        </div>
                      )}

                      <div className="col-span-12">
                        <InputField
                          label="Project API key"
                          type="text"
                          value={values?.projectApiKey}
                          name="projectApiKey"
                          placeholder="Enter Project API key"
                          className="rounded-lg !bg-white border border-grey-content-quaternary text-body-black"
                          isCompulsory={true}
                        />
                        <p className="text-grey-content-tertiary mt-3 font-medium">
                          {consumerType === "SANDBOX"
                            ? "This is the unique project API key generated for the project that holds the required assessment for the Consumer Trust Index computation. You can find this key in project settings."
                            : "This is the default project API key for the project that holds the required assessment for the Consumer Trust Index computation. If you would like to use a project you have created, replace with the key found in project settings in the console."}
                        </p>
                      </div>
                    </div>
                    <div className="mt-8">
                      <Button
                        title="Submit"
                        color="dark3"
                        category="3"
                        type="submit"
                        className="rounded-[4rem] mb-3 px-5 py-3 text-base text-left !w-auto inline-block font-medium "
                        disabled={
                          !isSubmittable(
                            values,
                            {
                              firstName: "",
                              lastName: "",
                              email: "",
                              projectApiKey: "",
                            },
                            ["firstName", "lastName", "email", "projectApiKey"]
                          )
                        }
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div
            id="login-form"
            className="absolute max-w-[450px] -translate-x-[105%] top-56"
          >
            {popUp === 1 && (
              <DemoGuidePopover
                title="Initialise consumer"
                body={`To initialise a consumer, our APIs need to receive some basic information about the consumer. We use this data to calculate the consumer's Consumer Trust Index. We never store and share this data on the Trust Network.<br/><br/>In Production, this data will be received via APIs between your system and ours.`}
                el={() => document.getElementById("login-form")}
                topOffset={0}
                leftOffset={0}
                arrowPosition="right"
                setPopUp={setPopUp}
                setNext={true}
                popUp={popUp}
              />
            )}
            {popUp === 2 && (
              <DemoGuidePopover
                title="Fill form with data provided in documentation"
                body={`In the Sandbox, we have prepared a manual input form enabling you to insert the details of the consumer profile you would like to initialise.<br/><br/>In our <u><a href="https://docs.trudenty.net/page/initial-data-to-initialise-consumers" target="_blank" style="color:#FBFBFB"> documentation</a></u>, we have provided 5 additional consumer profiles that can be used to test the “Consumer initialisation” process`}
                el={() => document.getElementById("login-form")}
                topOffset={0}
                leftOffset={0}
                arrowPosition="right"
                setPopUp={setPopUp}
                setPrev={true}
                popUp={popUp}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guide;
