import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IRootState } from "../store";

export interface IUserDetailsInterface {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string | null;
  mobile_number: string;
  image: string | null;
  loyalty_number: string | null;
  trudenty_id: string;
  address: {
    id: string;
    address: string;
    country: string | null;
    state: string | null;
    city: string | null;
    postal_code: string | null;
  };
  total_score: number;
  risk_level: string;
  consent_status: string;
  request_timestamp: Date | string;
  yapily_user_id: string | null;
  consumer_type: string;
  consent_tokens?: {
    id: string;
    consent_id: string;
    authorized_at: string;
    status: string;
    last_confirmed_at: string;
    reconfirm_by: string;
    bank_name: string | null;
  }[];
}

export interface AuthSliceType {
  userDetails?: IUserDetailsInterface | null;
}

const initialState: AuthSliceType = {
  userDetails: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserDetails(
      state: AuthSliceType,
      action: PayloadAction<AuthSliceType | undefined>
    ) {
      state.userDetails = action.payload?.userDetails;
    },
    setLogoutData(state: AuthSliceType) {
      state.userDetails = null;
    },
  },
});

export const authSelector = (state: IRootState) => {
  return state?.auth?.userDetails;
};
export const { reducer } = authSlice;

export const { setUserDetails, setLogoutData } = authSlice.actions;

export default authSlice;
