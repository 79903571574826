import { authSelector } from "@/redux/slice/authSlice";
import { createContext } from "react";
import { useSelector } from "react-redux";

// Utils
export const userContext = createContext();

const UserContextProvider = (props) => {
  const userDetails = useSelector(authSelector);

  return (
    <userContext.Provider
      value={{
        userDetails,
      }}
    >
      {props.children}
    </userContext.Provider>
  );
};

export default UserContextProvider;
